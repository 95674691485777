import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Analytics from './components/Analytics';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import NewAccount from './components/NewAccount';
import SignIn from './components/SignIn';

// Layout component to wrap Navbar and Footer around the main content
const Layout = ({ children }) => (
  <>
    <Navbar />
    {children}
    <Footer />
  </>
);

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          {/* Main App Route */}
          <Route path="/" element={
            <>
              <Hero />
              <Analytics />
              <Newsletter />
            </>
          } />

          {/* Individual Routes */}
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/new-account" element={<NewAccount />} />
          <Route path="/sign-in" element={<SignIn />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
