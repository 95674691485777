import React, { useState } from 'react';

const NewAccount = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle registration logic here (e.g., send data to backend)
    console.log('Registering:', { email, password });
  };

  const handleGoogleSignIn = () => {
    // Handle Google sign-in logic here
    console.log('Sign in with Google');
  };

  const handleAppleSignIn = () => {
    // Handle Apple sign-in logic here
    console.log('Sign in with Apple');
  };

  return (
    <div className="max-w-md mx-auto py-8 px-4">
      <h2 className="text-2xl font-bold text-center mb-4">Create a New Account</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-gray-700">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <button type="submit" className="w-full bg-[#cefe55] text-black p-2 rounded hover:bg-[#b8d62b] transition duration-300">
          Register
        </button>
      </form>

      <div className="flex items-center justify-between my-6">
        <hr className="flex-grow border-gray-300" />
        <span className="mx-4 text-gray-500">OR</span>
        <hr className="flex-grow border-gray-300" />
      </div>

      <div className="flex flex-col space-y-4">
        <button
          onClick={handleGoogleSignIn}
          className="w-full bg-red-600 text-white p-2 rounded hover:bg-red-500 transition duration-300"
        >
          Sign in with Google
        </button>
        <button
          onClick={handleAppleSignIn}
          className="w-full bg-black text-white p-2 rounded hover:bg-gray-800 transition duration-300"
        >
          Sign in with Apple
        </button>
      </div>
    </div>
  );
};

export default NewAccount;
