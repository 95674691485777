import React from 'react';
import Laptop from '../assets/hero.png';

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
        <div className='flex flex-col justify-center'>
        <p className='text-[#a6d72c] font-bold text-4xl'>Why ADA Skills Matters?</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Pioneering AI Evolution for Accountants & Economists</h1>
          <p>
           Today, technology is disrupting nearly every profession, and finance is no exception. At Ada Skills, we understand that these changes can feel overwhelming.
           That’s why we’re creating a platform dedicated to developing the AI competencies that finance professionals will need for future success.
           Our purpose is simple: to equip finance experts with the knowledge, tools, and skills needed to lead confidently in an AI-driven landscape. 
           For businesses, this means access to cutting-edge strategies and insights that enhance profitability and innovation. 
           For individuals, it’s a pathway to skill-building and career advancement in a field that increasingly values tech-savvy, future-ready finance professionals.
          </p>
          <button className='bg-black text-[#cefe55] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
