import React from 'react';
import Typed from 'react-typed';
import bgImage from '../assets/service-1.png';

const Hero = () => {
  return (
    <div
      className="text-white h-screen bg-cover bg-center relative"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Overlay for darker background effect */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      <div className="relative max-w-[800px] w-full h-full mx-auto text-center flex flex-col justify-center items-center">
        <h1 className="md:text-9xl sm:text-6xl text-5xl font-bold md:py-8 animate-fadeIn">
          - A D A -
        </h1>

        <div className="flex justify-center items-center space-x-2">
          <p className="md:text-5xl sm:text-4xl text-2xl font-bold py-4">
            Adaption
          </p>
          <Typed
            className="md:text-5xl sm:text-4xl text-2xl font-bold"
            strings={['DATA', 'SKILLS', 'FUTURE']}
            typeSpeed={100}
            backSpeed={80}
            loop
          />
        </div>

        <p className="md:text-2xl text-lg font-bold text-gray-300 animate-fadeIn">
          GROWING WITH DATA ANALYTICS.
        </p>

        <button className="bg-[#cefe55] w-[200px] rounded-md font-medium my-6 py-3 text-black hover:bg-yellow-400 transition duration-300 transform hover:scale-105">
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Hero;
