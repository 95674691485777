import React from 'react';
import { FaGithubSquare, FaInstagram, FaTwitterSquare } from 'react-icons/fa';
import logo from '../assets/A4.png'; // Adjust path if necessary

const Footer = () => {
  return (
    <div className="max-w-[1040px] mx-auto py-8 px-4 grid gap-8 text-gray-300">
      <div className="text-center lg:text-left">
        {/* Logo Image */}
        <h1 className="text-3xl font-bold">
          <img src={logo} alt="ADA Logo" className="h-20 w-auto mx-auto lg:mx-0" /> {/* Adjust height/width if needed */}
        </h1>
        <p className="py-4">GROWING WITH DATA ANALYTICS.</p>
        <div className="flex justify-center lg:justify-start space-x-6 my-6">
         <a href="https://www.instagram.com/juxhino_kapllanaj/" target="_blank" rel="noopener noreferrer">
           <FaInstagram size={30} className="hover:text-[#E1306C] transition duration-300" />
         </a>
         <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
           <FaTwitterSquare size={30} className="hover:text-[#1DA1F2] transition duration-300" />
         </a>
         <a href="https://github.com/yourprofile" target="_blank" rel="noopener noreferrer">
           <FaGithubSquare size={30} className="hover:text-gray-400 transition duration-300" />
         </a>
      </div>

      </div>
      
      <div className="lg:col-span-2 flex flex-col lg:flex-row justify-between text-center lg:text-left mt-6 space-y-6 lg:space-y-0">
        <div>
          <h6 className="font-medium text-gray-500">Solutions</h6>
          <ul>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Analytics</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Data</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Adaption</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Innovation</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-500">Support</h6>
          <ul>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Pricing</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Documentation</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Guides</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">API Status</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-500">Company</h6>
          <ul>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">About</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Learn</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Skills</li>
            <li className="py-2 text-sm hover:text-[#cefe55] transition duration-300">Careers</li>
          </ul>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="text-center border-t text-gray-500 border-gray-600 mt-8 pt-4">
        <p className="text-sm">
          © 2024 All rights reserved by ADA-Skills, parts of "BINOMENA" Blockchain platform.
        </p>
      </div>
    </div>
  );
};

export default Footer;
