import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom'; // Import Link for navigation
import logo from '../assets/A4.png';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  // Toggle the mobile menu
  const handleNav = () => setNav(!nav);

  // Close the mobile menu when a link is clicked
  const handleLinkClick = () => setNav(false);

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      {/* Logo */}
      <h1 className='text-3xl font-bold'>
        <img src={logo} alt="ADA Logo" className="h-20 w-auto" />
      </h1>

      {/* Centered Navigation Links (Hidden on mobile) */}
      <ul className='hidden md:flex flex-1 justify-center space-x-8'>
        <li className='p-4'>
          <Link to="/" className='hover:text-gray-400 transition-colors duration-300 active:text-[#cefe55]'>
            Home
          </Link>
        </li>
        <li className='p-4'>
          <Link to="/analytics" className='hover:text-gray-400 transition-colors duration-300 active:text-[#cefe55]'>
            Skills
          </Link>
        </li>
        <li className='p-4'>
          <Link to="/newsletter" className='hover:text-gray-400 transition-colors duration-300 active:text-[#cefe55]'>
            About Us
          </Link>
        </li>
      </ul>

      {/* Right-Aligned Links (New Account & Sign-In) */}
      <div className='hidden md:flex space-x-4'>
        <Link to="/new-account" className='p-2 border border-[#cefe55] rounded-md cursor-pointer hover:text-gray-400 transition-colors duration-300 active:bg-[#cefe55] active:text-black'>
          New-Account
        </Link>
        <Link to="/sign-in" className='p-2 border border-[#cefe55] rounded-md cursor-pointer hover:text-gray-400 transition-colors duration-300 active:bg-[#cefe55] active:text-black'>
          Sign-In
        </Link>
      </div>

      {/* Hamburger Icon for Mobile */}
      <div onClick={handleNav} className='block md:hidden cursor-pointer'>
        {nav ? (
          <AiOutlineClose size={24} className='transition-transform duration-300' />
        ) : (
          <AiOutlineMenu size={24} className='transition-transform duration-300' />
        )}
      </div>

      {/* Mobile Menu */}
      <ul
        className={`fixed top-0 left-1/2 transform -translate-x-1/2 w-[70%] h-[50vh] bg-[#000300db] z-10 transition-transform duration-500 ${
          nav ? 'translate-y-0' : 'translate-y-[-100%]'
        } overflow-y-auto`}
      >
        <li className='p-4 border-b border-gray-600'>
          <Link to="/" onClick={handleLinkClick} className='hover:text-gray-400 active:text-[#cefe55]'>
            Home
          </Link>
        </li>
        <li className='p-4 border-b border-gray-600'>
          <Link to="/analytics" onClick={handleLinkClick} className='hover:text-gray-400 active:text-[#cefe55]'>
            Skills
          </Link>
        </li>
        <li className='p-4 border-b border-gray-600'>
          <Link to="/newsletter" onClick={handleLinkClick} className='hover:text-gray-400 active:text-[#cefe55]'>
            About Us
          </Link>
        </li>
        <li className='p-4 border-b border-gray-600'>
          <Link to="/new-account" onClick={handleLinkClick} className='hover:text-gray-400 active:text-[#cefe55]'>
            New-Account
          </Link>
        </li>
        <li className='p-4 border-b border-gray-600'>
          <Link to="/sign-in" onClick={handleLinkClick} className='hover:text-gray-400 active:text-[#cefe55]'>
            Sign-In
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
